import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-400, #A3A3A3);
  margin-bottom: 8px;
  break-inside: avoid;
  break-after: auto;
`;

export const PageBreak = styled.div`
  @media print {
    break-after: before;
    // margin-top: 32px;
    margin-bottom: 32px;
  }
`;

export const Head = styled.div`
  display: flex;
  padding: 9px 8px 6px 8px;
  gap: 12px;
  background-color: var(--Color-4, #F5F5F5);
`;

export const OrderNumber = styled.span`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #E5E5E5;
  color: var(--neutral-800, #262626);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const Text = styled.span`
  color: var(--neutral-900, var(--Color-2, #171717));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;

  ${({ $area }) => $area && css`
    
  `}

  ${({ $category }) => $category && css`
    color: var(--neutral-600, var(--Text, #525252));
    font-weight: 400;
  `}

  ${({ $goal }) => $goal && css`
    color: var(--generic-black, #000);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    word-break: break-word;
  `}

  ${({ $title }) => $title && css`
    color: var(--neutral-900, var(--Color-2, #171717));
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;

    @media screen and (max-width: 365px) {
      font-size: 9px;
      font-weight: 400;
      line-height: 16px;
    }
  `}

  ${({ $p }) => $p && css`
    color: #404040;
    line-height: 16px;
    letter-spacing: -0.24px;
    font-weight: 400;
    word-break: break-word;
  `}

   ${({ $stretch }) => $stretch && css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
  `}
`;

export const Row = styled.div`
  display: flex;
  border-top: 1px solid var(--Placeholder, #A3A3A3);
`;

export const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;

  @media screen and (max-width: 365px) {
    padding: 4px;
  }

  ${({ $hr }) => $hr && css`
    position: relative;

    ::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: calc(100% - 16px);
      margin-top: 8px;
      background-color: #A3A3A3;
    }
  `}
`;

export const Badge = styled.span`
  border-radius: 24px;
  background: var(--Input-border, #E5E5E5);
  padding: 0 6px;
  line-height: 15px;
  height: 15px;
  min-height: 15px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  text-transform: initial;
`;
