import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "elements";
import Cookies from "js-cookie";

import {
  Wrapper,
  Modal,
  Header,
  Heading,
  Title,
  Subtitle,
  CloseBtn,
  Body,
  Footer,
  FooterCol,
  SelectedCount,
  SelectedRemoveBtn,
  Background,
} from "./styled";

// @ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/Closee.svg";

// @ts-ignore
import { ReactComponent as CloseGrayIcon } from "assets/images/priorities/close-gray.svg";

// @ts-ignore
import { ReactComponent as FocusedIcon } from "../assets/icon.svg";

// import ActivityItem from "../components/ActivityItem";
import RequiredHours from "../components/RequiredHours";
import PendingList from "../components/PendingList";

// @ts-ignore
import { removeFocusedPriorities } from "Redux/APM/ListFocusedPriorities/action";

import { ListPendingPrioritiesState } from "Redux/APM/ListPendingPriorities/type";
import { updatePendingPriorities } from "Redux/APM/ListPendingPriorities/action";
import { IFocusedExtreme } from "Redux/APM/ListFocusedExtremes/type";

// Function to check if today is after Sunday midnight
const isAfterSundayMidnight = (): boolean => {
  const now = new Date();
  const dayOfWeek = now.getDay(); // Sunday is 0, Monday is 1, etc.
  const hours = now.getHours();

  // If today is Sunday and after midnight (00:00) or any other day, return true
  return (dayOfWeek === 0 && hours >= 0) || dayOfWeek > 0;
};

const getRemainingDaysUntilNextSunday = (): number => {
  const now = new Date();
  const nextSunday = new Date();
  nextSunday.setDate(now.getDate() + (7 - now.getDay()));
  nextSunday.setHours(0, 0, 0, 0); // Set to Sunday midnight

  const timeDiff = nextSunday.getTime() - now.getTime();
  return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Return remaining days
};

const PendingPopup = () => {
  const dispatch = useDispatch();

  const isPopupHidden = Cookies.get("pending_activities_popup_hidden") === "true";

  const [isRendered, setIsRendered] = useState(false);
  const [isActiveState, setIsActiveState] = useState(false);

  const [activitiesToUpdate, setActivitiesToUpdate] = useState(
    [] as { id: string; value: number; delegate?: number; original: number }[]
  );

  const [activitiesToDelete, setActivitiesToDelete] = useState(
    [] as { id: string; time: number }[]
  );

  const [checkedItems, setCheckedItems] = useState([] as string[]);

  const { data, duration, loading } = useSelector(
    (state: any) => state.listPendingPriorities as ListPendingPrioritiesState
  );

  const { progress } = useSelector((state: any) => state.listFocusedPrioritise);

  const { completed, prioritized } = progress;

  const freeMins = activitiesToUpdate.reduce(
    (acc, { value, original }) => acc + value,
    0
  );

  const totalMins = 168 * 60;
  const availableMins = totalMins - completed - prioritized;

  const requiredMins =
    availableMins -
    freeMins -
    (activitiesToDelete.length
      ? activitiesToDelete.reduce((acc, { time }) => acc + time, 0)
      : 0);
  
  useEffect(() => {
    const activitiesToUpdate = [] as { id: string; value: number; delegate?: number; original: number }[];

    data.forEach((item: IFocusedExtreme) => {
      const activity = item.prioritized_activity;
      const value = activity ? activity.total_time : item.task ? item.task.estimated_duration : 0;
      // const id = activity ? activity.id : item.task ? item.task.id : '';
      const delegate = activity?.management_time || 0;

      activitiesToUpdate.push({
        id: item?.id,
        value,
        delegate,
        original: value
      });
    });

    setActivitiesToUpdate(activitiesToUpdate);
  }, [data]);

  useEffect(() => {
    // if (true) {
    if (!isPopupHidden && isAfterSundayMidnight() && (duration - (requiredMins < 0 ? 0 : requiredMins)) > 0) {
      setIsRendered(true);
      setTimeout(() => setIsActiveState(true), 50);
    }
  }, [isPopupHidden, duration, requiredMins]);

  const handleClose = () => {
    setIsActiveState(false);
    Cookies.set("pending_activities_popup_hidden", "true", { expires: getRemainingDaysUntilNextSunday() });
  };

  const handleTimeChange = (id: string, itemId: string, value: number, original: number, delegate?: number) => {
    setActivitiesToUpdate((prev) => {
      const exists = prev.some((activity) => activity.id === id);

      if (exists) {
        return prev.map((activity) =>
          activity.id === id ? { ...activity, value, delegate, original } : activity
        );
      } else {
        return [...prev, { id, value, delegate, original }];
      }
    });
  };

  const handleRemove = (id: string, time: number) => {
    setActivitiesToDelete((prev) => {
      const exists = prev.some((activity) => activity.id === id);

      if (exists) {
        return prev.filter((activity) => activity.id !== id);
      } else {
        return [...prev, { id, time }];
      }
    });
  };

  const handleSubmit = () => {
    processSubmit(false);
  };

  const processSubmit = (nextWeek: boolean) => {
    if (activitiesToDelete.length === 0) {
      updatePendingActivities(nextWeek);
      return;
    }

    dispatch(
      removeFocusedPriorities(
        activitiesToDelete.map((item) => item.id),
        () => {
          updatePendingActivities(nextWeek);
        }
      )
    );
  };

  const updatePendingActivities = (nextWeek: boolean) => {
    dispatch(updatePendingPriorities({
      updates: activitiesToUpdate.map(item => ({
        focused_activity_id: item.id,
        estimated_duration: item.value,
        delegation_time: item.delegate
      }))
    }, { loader: true, fetch: true, listFocusedPriorities: true }, handleClose));
  };

  const handleComplete = () => {
    dispatch(updatePendingPriorities({
      updates: checkedItems.map((id) => ({
        focused_activity_id: id,
        completed: true
      }))
    }, { loader: true, fetch: true, listFocusedPriorities: true }));
  };

  const handlePostpone = () => {
    dispatch(updatePendingPriorities({
      updates: checkedItems.map((id) => ({
        focused_activity_id: id,
        add_to_next_week: true
      }))
    }));
  };

  const handleCheckboxChange = (id: string, checked: boolean) => {
    setCheckedItems((prev) =>
      checked ? [...prev, id] : prev.filter((item) => item !== id)
    );
  }

  const handleClearSelected = () => {
    setCheckedItems([]);
  }

  if (!isRendered) {
    return null;
  }

  return createPortal(
    <Wrapper $active={isActiveState}>
      <Modal $active={isActiveState}>
        <Header>
          <CloseBtn onClick={handleClose}>
            <CloseIcon />
          </CloseBtn>

          <Heading>
            <FocusedIcon />
            <Title>You have pending activities over the maximum hours for this week</Title>
            <Subtitle>Please adjust your activity estimates, delegate or reschedule activities for a different week.</Subtitle>
          </Heading>
        </Header>

        <Body>
          <RequiredHours
            value={requiredMins}
            secondary
          />

          <PendingList
            data={data}
            checkedItems={checkedItems}
            loading={loading}
            onTimeChange={handleTimeChange}
            onRemove={handleRemove}
            onCheckboxChange={handleCheckboxChange}
          />
        </Body>

        <Footer>
          {checkedItems.length ? (
            <FooterCol $secondary>
              <SelectedCount>
                {checkedItems.length} Selected

                <SelectedRemoveBtn title="Unselect All" onClick={handleClearSelected}>
                  <CloseGrayIcon />
                </SelectedRemoveBtn>
              </SelectedCount>

              <Button styleType="gray-outlined" onClick={handleComplete}>
                Complete
              </Button>
              
              <Button styleType="gray-outlined" onClick={handlePostpone}>
                Add To Next Week
              </Button>
            </FooterCol>
          ) : <FooterCol />}

          <FooterCol>
            <Button disabled={requiredMins <= 0} onClick={handleSubmit}>
              Save
            </Button>
          </FooterCol>
        </Footer>
      </Modal>
      <Background />
    </Wrapper>,
    document.getElementById("portal") as HTMLElement
  );
};

export default PendingPopup;
