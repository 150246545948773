import {
  listPendingPriorities as listPendingPrioritiesAPI,
  updatePendingPriorities as updatePendingPrioritiesAPI,
  // @ts-ignore
} from "../apm-apis";

// @ts-ignore
import { listFocusedPrioritise } from "../ListFocusedPriorities/action";

import { ActionTypes } from "./type";
import Notify from "utils/notify";

export const listPendingPriorities = (options = { loader: true }) => async (dispatch: any) => {
  try {
    if (options.loader) {
      dispatch({ type: ActionTypes.REQUEST });
    }

    // @ts-ignore
    const { data } = await listPendingPrioritiesAPI();

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
  } catch (error) {
    Notify.error(error, true);
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error,
    });
  }
};

export const updatePendingPriorities = (payload: any, options = { loader: true, fetch: false, listFocusedPriorities: true }, callback?: () => void) => async (dispatch: any) => {
  try {
    // @ts-ignore
    await updatePendingPrioritiesAPI(payload);

    if (callback) {
      callback();
    }

    if (options.fetch) {
      dispatch(listPendingPriorities());
    }

    if (options.listFocusedPriorities) {
      dispatch(listFocusedPrioritise());
    }
  } catch (error) {
    console.log(error);

    Notify.error(error, true);

    dispatch({
      type: ActionTypes.FAILURE,
      payload: error,
    });
  }
};
