import React, { useState, useCallback, useRef } from "react";
import Switch from "react-switch";
import useDeviceType from "../../../../Hooks/ResponsiveHook";
import { Dropdown } from "elements";
import _ from "lodash";

import LockedOver from "components/LockedOver/LockedOver";
import PopoverCustom from "components/PriorityApp/SimpleView/PopoverCustom/index.tsx";
import UnitsSelector from "../UnitsSelector/index.jsx";

import InputAreaAI from "components/InputAreaAI";

import { ReactComponent as ChevronRightIcon } from "assets/images/journeys/chevron-right.svg";
import { ReactComponent as ChevronRightIconMobile } from "assets/images/3_dot_Icon.svg";

import * as Styles from "../styled.js";
import { useDispatch } from "react-redux";

import { UpdateGoal } from "Redux/UpdateGoal/action.js";
import { UpdateUserArea } from "Redux/UpdateUserArea/action.js";

import { useDebouncedUpdates } from "utils/utility.js";

const items = [
  {
    key: "1",
    element: "Daily",
  },
  {
    key: "2",
    element: "Weekly",
  },
  {
    key: "3",
    element: "Monthly",
  },
  {
    key: "4",
    element: "Quarterly",
  },
  {
    key: "5",
    element: "Yearly",
  },
];

const MeasurementItem = ({
  data: item,
  index: i,
  firstIndex,
  userDataId,
  predefinedMetrics,
  customMetrics,
  containerRef,
  onAddMetricPopupOpen,
  onManageToggle,
}) => {
  const dispatch = useDispatch();
  const screenSizes = useDeviceType();
  const isMobile = screenSizes === "mobile";
  const isTablet = screenSizes === "iPad";

  const [goal, setGoal] = useState(item?.goal?.goal_name ?? "");
  const [isAssistantOpen, setIsAssistantOpen] = useState(false);

  const $field = useRef();
  const $measurement = useRef();

  useState(() => {
    if (i === firstIndex) {
      setTimeout(() => {
        if ($field.current) $field.current.focus();
      }, 150);
    }
  }, [i, firstIndex]);

  const updateGoal = useCallback(
    (name, value) => {
      dispatch(UpdateGoal(item?.goal?.id, { [name]: value }));
    },
    [item?.goal?.id, dispatch]
  );

  const getDebouncedUpdate = useDebouncedUpdates(updateGoal);

  const handleUpdate = useCallback(
    (name, value) => {
      const debouncedUpdate = getDebouncedUpdate(name);
      debouncedUpdate(value);
    },
    [getDebouncedUpdate]
  );

  const onUnitsChangeHandle = (e) => {
    if (e.key === "custom")
      onAddMetricPopupOpen(true);
    else if (e.key === "manage")
      onManageToggle();
  };

  const handleGoals = (value) => {
    setGoal(value);
    handleUpdate("goal_name", value);
  };

  const hanlePvt = ({ is_private, id }) => {
    const pvtFormData = new FormData();
    pvtFormData.append("is_private", !is_private);
    dispatch(UpdateUserArea(id, pvtFormData));
  };

  const handleMeasurementsChange = (field, value) => {
    handleUpdate(field, value);
  };

  const handleMeasurementsBulkChange = (values, close) => {
    dispatch(UpdateGoal(item?.goal?.id, values, () => {
      if (close) {
        handleAssistantClose();
      }
    }));
  }

  const handleFrequencyChange = (event) => {
    const item = items.find((i) => i.key === event.key);

    if (!item) return;

    handleUpdate("frequency", item.element);
  };

  const handleAssistantSelect = ({ text, value }) => {
    dispatch(
      UpdateGoal(
        item?.goal?.id,
        {
          measurement_metric_name: text /* .substring(0, 6) */,
          measurement_start_value: null,
          measurement_current_value: null,
          measurement_target_value: value,
        },
        () => {
          setTimeout(() => {
            if ($measurement.current) {
              $measurement.current.click();
            }
          }, 100);
        }
      )
    );
  };

  const handleAssistantOpen = (value) => {
    setIsAssistantOpen(value);
  };

  const handleAssistantClose = () => {
    setIsAssistantOpen(false);
    $measurement.current.click();
  }

  const isLocked = item?.is_private && userDataId !== item?.user;

  return (
    <Styles.Row border={item?.color}>
      {isLocked && <LockedOver width="32px" display="row" index={i + 1} />}

      {/* {userDataId === item?.user && (
        <Styles.SwitchWrapperMobile>
          <Styles.TextPvt>Private</Styles.TextPvt>
          <Switch
            onChange={hanlePvt}
            checked={item?.is_private}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#1371a6"
            offColor="#cdcdcd"
            handleDiameter={12}
            height={18}
            width={30}
          />
        </Styles.SwitchWrapperMobile>
      )} */}

      <Styles.RowContent>
        <Styles.LeftSection>
          <Styles.WrapAvatar>
            <Styles.IdAvatar size={24}>{i + 1}</Styles.IdAvatar>
          </Styles.WrapAvatar>

          <Styles.WrapHead>
            <Styles.WrapText>
              <Styles.Text $area>{item.name}</Styles.Text>
              <Styles.Text $category>{item?.goal?.category_name}</Styles.Text>
            </Styles.WrapText>

            {(isMobile || isTablet) && (
              <Styles.Text $ml={-20} $title>
                3. GOAL
              </Styles.Text>
            )}
            <InputAreaAI
              id={`goal_${item?.id}`}
              value={goal}
              placeholder="State your goal"
              onChange={handleGoals}
              style={{
                wordBreak: "break-word",
                marginLeft: isMobile || isTablet ? -20 : 0,
              }}
              max={300}
              tooltip={i === firstIndex}
              assistant={{
                type: "goal",
                color: item?.color,
                title: "Goal Statement",
                order: i + 1,
                area: item.name,
                category: item?.goal?.category_name,
              }}
              ref={$field}
              containerRef={containerRef}
              disabled={isLocked}
            />
          </Styles.WrapHead>
        </Styles.LeftSection>

        <Styles.OldSection>
          <Styles.OldMeasurement>
            {item.goal?.measurement && !item?.goal?.gap_percentage
              ? `# ${item.goal?.measurement}`
              : null}
          </Styles.OldMeasurement>
        </Styles.OldSection>

        <Styles.MidSection>
          {(isMobile || isTablet) && (
            <Styles.Text $mt={1} $ml={20} $title>
              4. Measurement
            </Styles.Text>
          )}
          <PopoverCustom
            width={isMobile ? "250px" : null}
            keepOpen={isAssistantOpen}
            popoverStyle={{
              containerStyle: {
                zIndex: "998",
              },
            }}
            defaultBoundaryElement
            content={
              <UnitsSelector
                key={item?.id}
                id={item?.id}
                predefinedData={predefinedMetrics}
                customData={customMetrics}
                goalData={item?.goal}
                assistant={{
                  type: "measurement",
                  color: item?.color,
                  title: "Measurement",
                  order: i + 1,
                  area: item.name,
                  category: item?.goal?.category_name,
                  goal: item?.goal?.goal_name,
                  onSelect: handleAssistantSelect,
                }}
                onClick={onUnitsChangeHandle}
                onChange={handleMeasurementsChange}
                onBulkChange={handleMeasurementsBulkChange}
                onOpen={handleAssistantOpen}
                onClose={handleAssistantClose}
              />
            }
          >
            <Styles.PopoverToggle>
              <InputAreaAI
                id={`measurement_${item?.id}`}
                value={`${
                  item?.goal?.measurement_current_value ||
                  item?.goal?.measurement_target_value
                    ? `${item?.goal?.measurement_current_value ?? " - "}/${
                        item?.goal?.measurement_target_value ?? " - "
                      }`
                    : "-"
                } ${item?.goal?.measurement_metric_name}`}
                title={`${
                  item?.goal?.measurement_current_value ||
                  item?.goal?.measurement_target_value
                    ? `${item?.goal?.measurement_current_value ?? " - "}/${
                        item?.goal?.measurement_target_value ?? " - "
                      }`
                    : "-"
                } ${item?.goal?.measurement_metric_name}`}
                suffix={item?.goal?.measurement_metric_name}
                style={{ minWidth: 106, textAlign: "left", marginRight: 100 }}
                assistant={{
                  type: "measurement",
                  color: item?.color,
                  title: "Measurement",
                  order: i + 1,
                  area: item.name,
                  category: item?.goal?.category_name,
                  goal: item?.goal?.goal_name,
                  onSelect: handleAssistantSelect,
                }}
                ref={$measurement}
                readOnly
              />
            </Styles.PopoverToggle>
          </PopoverCustom>
        </Styles.MidSection>

        {!isMobile && !isTablet && (
          <Styles.GapSection>
            <Styles.GapText>
              {item?.goal?.gap_value ?? "-"}{" "}
              <span title={item?.goal?.measurement_metric_name}>
                {item?.goal?.measurement_metric_name}
              </span>
            </Styles.GapText>
          </Styles.GapSection>
        )}

        {!isMobile && !isTablet && (
          <Styles.GapPercentSection>
            <Styles.GapText>
              {item?.goal?.gap_percentage
                ? `${item?.goal?.gap_percentage}%`
                : "-"}
            </Styles.GapText>
          </Styles.GapPercentSection>
        )}

        {!isMobile && !isTablet && (
          <Styles.FrequencySection>
            <Dropdown
              value={
                items.find((i) => i.element === item?.goal?.frequency)?.key ||
                ""
              }
              menuItems={items}
              onChange={handleFrequencyChange}
              secondary
            >
              <Styles.FrequencyToggler>
                {item?.goal?.frequency}
                <ChevronRightIcon />
              </Styles.FrequencyToggler>
            </Dropdown>
          </Styles.FrequencySection>
        )}
        {(isMobile || isTablet) && (
          <Styles.BottomSection>
            {" "}
            <Styles.Box>
              <Styles.Text $mt={1} $title>
                Gap
              </Styles.Text>

              <Styles.GapSection>
                <Styles.GapText>
                  {item?.goal?.gap_value ?? "-"}{" "}
                  <span title={item?.goal?.measurement_metric_name}>
                    {item?.goal?.measurement_metric_name}
                  </span>
                </Styles.GapText>
              </Styles.GapSection>
            </Styles.Box>
            <Styles.Box>
              <Styles.Text $mt={1} $title>
                Gap%
              </Styles.Text>

              <Styles.GapPercentSection>
                <Styles.GapText>
                  {item?.goal?.gap_percentage
                    ? `${item?.goal?.gap_percentage}%`
                    : "-"}
                </Styles.GapText>
              </Styles.GapPercentSection>
            </Styles.Box>
            <Styles.Box $width={"40%"}>
              <Styles.Text $ml={30} $mt={1} $title>
                frequency
              </Styles.Text>

              <Styles.FrequencySection>
                <Dropdown
                  value={
                    items.find((i) => i.element === item?.goal?.frequency)
                      ?.key || ""
                  }
                  menuItems={items}
                  onChange={handleFrequencyChange}
                  secondary
                >
                  <Styles.FrequencyToggler>
                    {item?.goal?.frequency}
                    <ChevronRightIconMobile />
                  </Styles.FrequencyToggler>
                </Dropdown>
              </Styles.FrequencySection>
            </Styles.Box>
          </Styles.BottomSection>
        )}

        {/* 🟠 According to the Figma design */}
        {/* {userData?.id === item?.user && (
          <Styles.SwitchWrapper>
            <Switch
              onChange={() => hanlePvt(item)}
              checked={item?.is_private}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#1371a6"
              offColor="#cdcdcd"
              handleDiameter={12}
              height={18}
              width={30}
            />
          </Styles.SwitchWrapper>
        )} */}
      </Styles.RowContent>
    </Styles.Row>
  );
};

export default MeasurementItem;