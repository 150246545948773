import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import ActivityItem from "../ActivityItem";

import { Wrapper, Group, LoaderWrapper, SubTitle } from "./styled";

import { IFocusedExtreme } from "Redux/APM/ListFocusedExtremes/type";

interface IProps {
  data: IFocusedExtreme[];
  checkedItems: string[];
  loading: boolean;
  onTimeChange?: (id: string, itemId: string, value: number, original: number, delegate?: number) => void;
  onRemove?: (id: string, time: number) => void;
  onCheckboxChange: (id: string, checked: boolean) => void;
}

const PendingList: FC<IProps> = ({ data, checkedItems, loading, onTimeChange, onRemove, onCheckboxChange }) => {
  return (
    <Wrapper>
      <Group>
        <SubTitle>Est.Duration</SubTitle>

        {loading ? (
          <Loader />
        ) : (
          data.map((item) => (
            <ActivityItem
              id={item.id}
              activity={item.prioritized_activity}
              task={item.task}
              checked={checkedItems.includes(item.id)}
              key={item.id}
              showCheckbox
              showDelegatePopover
              adjustDuration
              onTimeChange={onTimeChange}
              onRemove={onRemove}
              onCheckboxChange={onCheckboxChange}
            />
          ))
        )}
      </Group>
    </Wrapper>
  );
};

const Loader = () => {
  return (
    <LoaderWrapper>
      <Skeleton width={"100%"} height={42} count={1} inline={true} />
      <Skeleton width={"100%"} height={42} count={1} inline={true} />
      <Skeleton width={"100%"} height={42} count={1} inline={true} />
    </LoaderWrapper>
  );
};

export default PendingList;
