import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { getCookie } from "utils/StorageVariables";
import { RetrieveMasterPlanDetails } from "Redux/RetrieveMasterPlan/action";
import { PrioritizeAreaAndCategories } from "Redux/PrioritizeAreaAndCategories/action";
import { SetSelectedGoalAction } from "Redux/Global/action";

import { AreaItemDraggable } from "components/Hierarchy/AreaItem";

import { ListInner } from "./styled";
import Sidebar from "../Sidebar";

const SortArr = (data) => {
  if (!data) return [];

  return data?.sort(function (a, b) {
    return a?.goal?.priority - b?.goal?.priority;
  });
};

const GoalsSidebar = ({ step, active, onClose, CustomWidth }) => {
  const [DragData, setDragData] = useState([]);
  const [payload, setPayload] = useState([]);

  const dispatch = useDispatch();

  const { masterPlanDetails } = useSelector(
    (state) => state.RetrieveMasterPlanDetailsReducer
  );
  const { selectedGoalAction } = useSelector((state) => state.globalReducer);

  let sortedData = SortArr(masterPlanDetails?.areas);

  useEffect(() => {
    if (!masterPlanDetails) return;

    setDragData(sortedData);

    if (selectedGoalAction && selectedGoalAction?.i !== -1) {
      dispatch(
        SetSelectedGoalAction({
          ...sortedData[selectedGoalAction.i],
          i: selectedGoalAction?.i,
        })
      );
    } else {
      dispatch(
        SetSelectedGoalAction({
          ...sortedData[0],
          i: 0,
        })
      );
    }
  }, [masterPlanDetails?.id]);

  useEffect(() => {
    dispatch(
      SetSelectedGoalAction({
        ...sortedData[0],
        i: 0,
      })
    );
  }, [step]);

  useEffect(() => {
    let MasterPlanId = getCookie("MasterPlanId");
    dispatch(RetrieveMasterPlanDetails(MasterPlanId));
  }, []);

  const handlePriority = (startOrder, DragData) => {
    let copyArr = [...DragData];
    if (copyArr[startOrder?.source?.index]?.goal) {
      copyArr[startOrder?.source?.index].goal.priority =
        startOrder?.destination?.index + 1;
    }
    if (copyArr[startOrder?.destination?.index]?.goal) {
      copyArr[startOrder?.destination?.index].goal.priority =
        startOrder?.source?.index + 1;
    }

    return copyArr;
  };

  const handleOrderAgain = (arr, start, end) => {
    let copyArr = [...arr];
    let element = copyArr.splice(start, 1)[0];

    copyArr.splice(end, 0, element);

    return copyArr;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    let settedPiority = handlePriority(result, sortedData);
    let orderedArr = handleOrderAgain(
      settedPiority,
      result?.source?.index,
      result?.destination?.index
    );

    let obj = {
      goal: orderedArr[result?.destination?.index]?.goal?.id,
      priority: orderedArr[result?.destination?.index]?.goal?.priority,
    };

    setPayload([...payload, obj]);
    setDragData(orderedArr);
    handlePrioritize(orderedArr);
  };

  const handlePrioritize = (orderedArr) => {
    let masterplanId = getCookie("MasterPlanId");

    let payloads = [...orderedArr].map((item, i) => {
      return {
        goal: item?.goal?.id,
        priority: i + 1,
      };
    });

    dispatch(PrioritizeAreaAndCategories(payloads, masterplanId));

    dispatch(
      SetSelectedGoalAction({
        ...orderedArr[selectedGoalAction?.i],
        i: selectedGoalAction?.i,
      })
    );
  };

  const handleGoalClick = (index) => {
    dispatch(
      SetSelectedGoalAction({
        ...sortedData[index],
        i: index,
      })
    );
  };

  return (
    <Sidebar title="Goals" active={active} CustomWidth={CustomWidth} onToggle={onClose}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <ListInner {...provided.droppableProps} ref={provided.innerRef}>
              {DragData.map((item) => ({
                ...item,
                order: item.goal.priority,
                title: item.name,
                category: item.goal.category_name,
              })).map((item, i) => (
                <AreaItemDraggable
                  data={item}
                  draggable
                  highlighted={step === 6 && selectedGoalAction?.i === i}
                  key={item.id}
                  index={i}
                  onClick={handleGoalClick}
                />
              ))}
              {provided.placeholder}
            </ListInner>
          )}
        </Droppable>
      </DragDropContext>
    </Sidebar>
  );
};

export default GoalsSidebar;
