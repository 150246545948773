import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import ActivityItem from "../ActivityItem";

import {
  Wrapper,
  Title,
  Time,
  TableHead,
  TableCol,
  Group,
  GroupHead,
  LoaderWrapper,
} from "./styled";

import { IData } from "Redux/APM/ListFocusedExtremes/type";

interface IProps {
  data: IData;
  items: { [id: string]: { id: string; estimation: number; original: number; } };
  loading: boolean;
  onTimeChange?: (id: string, itemId: string, value: number, original: number) => void;
  onRemove?: (id: string, time: number) => void;
}

const convertMinutesToTimeFormat = (time?: number | number) => {
  if (!time) return "0h";

  const hours = Math.floor(time / 60);
  const mins = time % 60;

  let result = "";

  if (hours) {
    result += `${hours}h`;
  }

  if (mins) {
    if (result) result += " ";
    result += `${mins}m`;
  }

  return result || `0h`;
};

const List: FC<IProps> = ({ data, items, loading, onTimeChange, onRemove }) => {
  const { itemsById, most_time_consuming, least_time_consuming, least_priority } = data;

  const mostTimeConsuming = most_time_consuming.map(id => itemsById[id]);
  const leastTimeConsuming = least_time_consuming.map(id => itemsById[id]);
  const leastPriority = least_priority.map(id => itemsById[id]);

  const lowPriorityTime = leastPriority.reduce((acc, item) => {
    const estimation = items[item.id]?.estimation || 0;
    return acc + estimation;
  }, 0);  

  return (
    <Wrapper>
      <Group>
        <Title>Most Time-consuming Activities</Title>

        <TableHead>
          <TableCol>Priority Score</TableCol>
          <TableCol>Est.Duration</TableCol>
        </TableHead>

        {loading ? (
          <Loader />
        ) : (
          mostTimeConsuming.map((item) => (
            <ActivityItem
              id={item.id}
              activity={item.prioritized_activity}
              task={item.task}
              duration={items[item.id]?.estimation || 0}
              // priority={item.priority}
              key={item.id}
              adjustDuration
              showRemoveBtn
              showHLA
              showSeeGoal
              onTimeChange={onTimeChange}
              onRemove={onRemove}
            />
          ))
        )}
      </Group>

      <Group>
        <Title>Least Time-consuming Activities</Title>

        {loading ? (
          <Loader />
        ) : (
          leastTimeConsuming.map((item) => (
            <ActivityItem
              id={item.id}
              activity={item.prioritized_activity}
              task={item.task}
              duration={items[item.id]?.estimation || 0}
              key={item.id}
              adjustDuration
              showRemoveBtn
              onTimeChange={onTimeChange}
              onRemove={onRemove}
            />
          ))
        )}
      </Group>

      <Group>
        <GroupHead>
          <Title>Low Priority Activities</Title>

          <Time>
            Total: <span>{convertMinutesToTimeFormat(lowPriorityTime)}</span>
          </Time>
        </GroupHead>

        {loading ? (
          <Loader />
        ) : (
          leastPriority.map((item) => (
            <ActivityItem
              id={item.id}
              activity={item.prioritized_activity}
              task={item.task}
              duration={items[item.id]?.estimation || 0}
              // priority={item.priority}
              key={item.id}
              adjustDuration
              showRemoveBtn
              showHLA
              showSeeGoal
              onTimeChange={onTimeChange}
              onRemove={onRemove}
            />
          ))
        )}
      </Group>
    </Wrapper>
  );
};

const Loader = () => {
  return (
    <LoaderWrapper>
      <Skeleton width={"100%"} height={42} count={1} inline={true} />
      <Skeleton width={"100%"} height={42} count={1} inline={true} />
      <Skeleton width={"100%"} height={42} count={1} inline={true} />
    </LoaderWrapper>
  );
};

export default List;
