import { FC, useState } from "react";
import { useDispatch } from "react-redux";

import { Checkbox, Tooltip } from "elements";

import { Dropdown, Menu } from "antd";

// @ts-ignore
import { removeFocusedPriority } from "Redux/APM/ListFocusedPriorities/action";

// @ts-ignore
import { ReactComponent as DotsVertical } from "assets/images/priorities/dots-vertical.svg";

import { UpdateActions } from "Redux/UpdateActions/action";

// @ts-ignore
import { completeTaskAction } from "../../../../../../Redux/APM/CompleteTask/action";

// import { FaCheck } from "react-icons/fa6";
import { Type } from "components/PriorityApp/SimpleView/Elements";

import {
  ActivitiesList,
  ListAvatar,
  ListCheck,
  ListDrag,
  ListLeft,
  ListRight,
  ListTime,
  ListTitle,
} from "../../styled";
import { updatePendingPriorities } from "Redux/APM/ListPendingPriorities/action";

interface IProps {
  data: {
    id: number;
    prioritized_activity?: {
      color: string;
      id?: string;
      estimated_weekly_duration: number;
      total_time: number;
      action?: {
        type: string;
        context: string;
        id?: string;
        completed: boolean;
      };
    };
    task?: {
      name: string;
      priority: string;
      id?: string;
      completed: boolean;
      estimated_duration?: number;
    };
  };
  index: number;
}

const StaticItem: FC<IProps> = ({
  data
}) => {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState<boolean>(false);
  const isHabit = data?.prioritized_activity?.action?.type === "Habit";
  const activityType = data?.prioritized_activity
    ? data?.prioritized_activity?.action?.type
    : "task";

  const { prioritized_activity, task } = data;
  const label = prioritized_activity?.action?.context || task?.name || "";

  const estimatedDuration = prioritized_activity?.total_time
    ? prioritized_activity?.estimated_weekly_duration
    : task?.estimated_duration || 0;

  const estimatedDurationHours = Math.floor(estimatedDuration / 60);
  const estimatedDurationMinutes = estimatedDuration % 60;

  const formattedDuration = `${
    estimatedDurationHours > 0 ? estimatedDurationHours + "h " : estimatedDurationMinutes ? "00h " : ""
  }${
    estimatedDurationMinutes > 0
      ? estimatedDurationMinutes + "m"
      : estimatedDurationHours
      ? "00m"
      : ""
  }`;

  const removeFocused = (id: number) => {
    dispatch(removeFocusedPriority(id));
  };

  const completeTask = async (data: any) => {
    if (data?.prioritized_activity && data?.prioritized_activity?.action?.id) {
      await dispatch(
        UpdateActions(
          data.prioritized_activity.action.id,
          { completed: true },
          false,
          {
            callback: () => {},
            showLoader: true,
            isApm: true,
          }
        )
      );
    } else {
      dispatch(completeTaskAction(data?.task?.id));
    }
  };

  const moveToNextWeek = (id: number | string) => {
    dispatch(updatePendingPriorities({
      updates: [{
        focused_activity_id: id,
        add_to_next_week: true
      }]
    }, { loader: true, fetch: true, listFocusedPriorities: true }));
  };

  const items: any = [];

  items.push(
    {
      key: "next_week",
      label: <Menu.Item key="next_week">Move to next week</Menu.Item>,
      className: "apm-drawer-dropdownMenu",
    },
    {
      key: "remove",
      label: <Menu.Item key="remove">Remove</Menu.Item>,
      className: "apm-drawer-dropdownMenu apm-drawer-dropdownMenuRemove",
    }
  );

  const onChange = () => {
    setChecked(!checked);

    if (data?.prioritized_activity && data?.prioritized_activity?.action?.id) {
      dispatch(
        UpdateActions(
          data.prioritized_activity.action.id,
          { completed: true },
          false,
          {
            callback: () => {},
            showLoader: true,
            isApm: true,
          }
        )
      );
    } else {
      dispatch(completeTaskAction(data?.task?.id));
    }
  };

  return (
    <ActivitiesList $pending>
      <ListLeft>
        {activityType && (
          <Type
            type={activityType}
            isClick
            containerStyle={{
              left: "157px",
            }}
          />
        )}
        <ListTitle /* completed={completed} */>{label}</ListTitle>
      </ListLeft>

      <ListRight>
        <ListTime>{formattedDuration}</ListTime>

        {/* completed ||  */isHabit ? (
          <div
            style={{
              width: "18px",
              height: "18px",
              visibility: "hidden",
              opacity: 0,
            }}
          ></div>
        ) : (
          <Tooltip title="Complete">
            <ListCheck>
              <Checkbox
                id={`check_${data.id}`}
                checked={checked}
                onChange={onChange}
              />
            </ListCheck>
          </Tooltip>
        )}

        <Dropdown
          menu={{
            items,
            onClick: (e) => {
              if (e.key === "remove") {
                removeFocused(data?.id);
              } else if (e.key === "complete") {
                completeTask(data);
              } else if (e.key === 'next_week') {
                moveToNextWeek(data?.id);
              }
            },
          }}
          placement="bottomRight"
          trigger={["click"]}
          overlayClassName="apm-drawer-dropdown apm-drawer-dropdown--static"
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
        >
          <DotsVertical
            style={{
              cursor: "pointer",
            }}
          />
        </Dropdown>
      </ListRight>
    </ActivitiesList>
  );
};

export default StaticItem;
