import { Link } from "react-router-dom";
import styled, { css } from 'styled-components';

import Button from "components/Button";

export const Container = styled.div`
  width: 100%;

    
 @media screen and (max-width: 1256px) {
  border-top : 1px solid lightgray;
}


`;

export const Step = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 32px 9px 22px;
  background-color: #fff;

  ${({ $header }) => $header && css`
    border-bottom: 1px solid #C1CAD1;
  `}

  ${({ theme }) => theme.max("sm")`
    padding: 9px 16px;
  `}

  ${({ $footer }) => $footer && css`
    padding: 0 22px;
    height: 50px;
    border-top: 1px solid #C1CAD1;
    background-color: #fff;
    position: relative;
    z-index: 1000;

      
    @media screen and (max-width: 1256px) {
      position: absolute;
      bottom: 0;
    }


    ${({ $pagination }) => $pagination && css`
      height: 56px;
    `}
  `}
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 385px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    width: 260px;
  }
`;

export const Title = styled.h1`
  color: #06203a;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  vertical-align: middle;
  font-size: 18px;
  line-height: 24px;
  margin: 0;

  transform: translateX(-36px);
  transition: transform .3s;

  ${({ $active }) => $active && `
    transform: translateX(0);
  `};

  @media screen and (max-width: 768px) {
    font-size: 14px;
    margin-left: 20px;
  }
  @media (min-width: 768px) and (max-width: 1256px) {
    margin-left: 15px;
  }

`;

export const Toggler = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  background: transparent;
  padding: 0;
  border: none;

  img {
    transform: rotate(180deg);
  }

  opacity: 0;
  visibility: hidden;
  transform: translateX(-36px);
  transition: all .3s;

  :hover {
    transform: translateX(2px);
  }

  ${({ $active }) => $active && `
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
  `};
`;

export const ButtonWrapper = styled.div`
  // width: 350px;
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: flex-end;
  flex-direction: row;
`;

export const NextButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StepsToggler = styled.button`
  background: transparent;
  border: none;
  display: flex;
  gap: 7px;
  color: var(--neutral-500, var(--Input-header, #737373));
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  padding: 0;

  svg {
    transform: rotate(90deg);
  }
`;

export const FooterLeft = styled.div`
  
`;

export const FooterMiddle = styled.div`
  
`;

export const SecondaryHeader = styled.div`
  background-color: #fff;
  height: 32px;
  width: 100%;
  display: flex;
  padding: 4px 40px 4px 16px; // 28
  border-bottom: 1px solid #C1CAD1;
`;

export const ContainerItem = styled.div`
  width: 100%;
  height: calc(100% - 54px - 48px);
  overflow-y: scroll;

  ${({ $pagination }) => $pagination && css`
    height: calc(100% - 54px - 48px - 10px);
  `}

  ${({ $withHeader }) => $withHeader && `
    height: calc(100% - 54px - 48px - 24px);
  `};

  ${({ $smaller }) => $smaller && `
    height: calc(100% - 94px);
  `};

  /* @media screen and (max-width: 1439px) {
    height: calc(100% - 90px - 48px - 24px);
  } */

  ::-webkit-scrollbar {
    width: 26px;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important;
  }
  
  ::-webkit-scrollbar-thumb {
    border: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #acb4bc;
  }
`;

export const ModifiedButton = styled(Button)`
  background: linear-gradient(161.19deg, #1263de 18.35%, #448fff 74.45%);
`;

export const PrimaryLink = styled(Link)`
  color: var(--primary-500-main, #1271A6);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 6px 0;
  gap: 6px;
  text-decoration: none;

  img {
    transition: transform .3s;
  }

  &:hover {
    img {
      transform: translate(-4px);
    }
  }
`;

export const PrimaryLinkJourney = styled(Link)`
  color: var(--primary-500-main, #1271A6);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 6px 0;
  gap: 6px;
  text-decoration: none;
`;
