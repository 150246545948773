import React from "react";
import { Drawer } from "antd";
import styled , { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { DrawerMenuAction } from "Redux/Global/action";
import {
  APM_PATH,
  COMPLETED_GOALS_PATH,
  PROFILE_PATH,
  JOURNEY_PATH,
  COACHING_PATH,
} from "utils/routes";
import { ReactComponent as ExpandArrow } from "assets/images/expand_arrow.svg";
import { ReactComponent as Dashboard } from "assets/images/sidebar/dashboard.svg";
import { ReactComponent as Flag } from "assets/images/sidebar/flag.svg";
import { ReactComponent as Tick } from "assets/images/sidebar/tick.svg";
import { ReactComponent as User } from "assets/images/sidebar/profile.svg";
import { ReactComponent as Coaching } from "assets/images/sidebar/coaching.svg";

const DrawerMenu = ({ position }) => {
  const dispatch = useDispatch();
  const { drawerMenu } = useSelector((state) => state.globalReducer);
  const location = useLocation().pathname;

  let sideNavRoutes = [];

  if (location.includes(APM_PATH)) {
    sideNavRoutes = [
      {
        logo: <Dashboard />,
        title: "Priority Dashboard",
        route: APM_PATH,
        disabled: false,
        splitRange: 1,
      },
      {
        logo: <Flag />,
        title: "Journeys",
        route: JOURNEY_PATH,
        splitRange: 1,
        disabled: false,
      },
      {
        logo: <User />,
        title: "User Profile",
        route: `${APM_PATH}${PROFILE_PATH}`,
        disabled: false,
        splitRange: 1,
      },
    ];

    if (userData?.type === "Coach") {
      sideNavRoutes.push({
        logo: <Coaching />,
        title: "Coaching",
        route: `${APM_PATH}${COACHING_PATH}`,
        disabled: false,
        splitRange: 1,
        includes: true,
      });
    }
  } else {
    sideNavRoutes = [
      {
        logo: <Flag />,
        title: "Journeys",
        route: JOURNEY_PATH,
        splitRange: 1,
        disabled: false,
      },
      {
        logo: <Tick />,
        title: "Completed Goals",
        route: COMPLETED_GOALS_PATH,
        disabled: false,
        splitRange: 1,
      },
    ];
  }
  const closeDrawer = () => {
    dispatch(DrawerMenuAction(false));
  };

  return (
    <Wrapper>
      <Drawer
        width={"75%"}
        placement="left"
        onClose={closeDrawer}
        open={drawerMenu}
        title={(<ExpandIconWrapper
          type="button"
          isExpand={drawerMenu}
          onClick={closeDrawer}
        >
          <ExpandArrow />
        </ExpandIconWrapper>)}
        headerStyle={{ padding: 0, marginBottom: 0 }}
        bodyStyle={{ padding: 0 }}
        closable={false}
      >
        <LinkContainer>
          {sideNavRoutes?.map((item) => {
            const status = item?.includes
              ? location.includes(item.route)
              : location === item.route;

            return (
              <NavigationLink
                to={item.route}
                $expand={drawerMenu}
                $status={status}
                $disabled={item.disabled}
                $hidden={item.hidden && !status}
                title={item.title}
                key={item.title}
                onClick={closeDrawer}
              >
                <IconWrapper isExpand={drawerMenu} isDisabled={item.disabled}>
                  <Icon status={status}>{item.logo}</Icon>
                </IconWrapper>
                <LinkText isExpand={drawerMenu} status={status}>
                  {item.title}
                </LinkText>
              </NavigationLink>
            );
          })}
        </LinkContainer>
      </Drawer>
    </Wrapper>
  );
};

export default DrawerMenu;

const Wrapper = styled.div``;

const LinkContainer = styled.div`
  background-color: ${({ theme }) => theme?.background?.light};
`;

const NavigationLink = styled(Link)`
  border-left: 4px solid transparent;
  background-color: ${({ $status, theme }) => $status ? theme?.active : theme?.background?.light};
  text-decoration: none;
  display: flex;
  line-height: 20px;
  letter-spacing: 0;
  padding-right: 4px;
  align-items: center;
  justify-content: ${({ $expand }) => ($expand ? "flex-start" : "center")};
  height: 55px;
  pointer-events: ${({ $disabled }) => $disabled && "none"};
  cursor: ${({ $disabled }) => $disabled && "not-allowed"};
  opacity: ${({ $disabled }) => $disabled && "0.5"};

  ${({ $status, theme }) => $status && theme && css`
    border-left-color: ${theme?.primary?.light};
  `}

  ${({ $hidden }) => $hidden && css`
    display: none;
  `}

  ${({ $status }) => $status || css`
    &:hover {
      background-color: #E8EBEE;
    }
  `}
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${({ isDisabled }) => isDisabled && "none"};
  width: "100%";

  @media (max-width: 768px) {
    margin-left: 15px;
  }
`;

const Icon = styled.div`
  svg {
    path {
      fill: ${({ status, theme }) => status && theme?.primary?.light};
    }
  }
`;

const ExpandIconWrapper = styled.button`
  display: flex;
  justify-content: start;
  align-items: center;
  height: auto;
  min-height: 60px;
  width: 100%;
  padding: 8px 15px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  transform: rotate(180deg);
  svg {
    path {
      fill: ${({ status, theme }) => status && theme?.primary?.light};
    }
  }
`;

const LinkText = styled.p`
  width: 85%;
  margin: 0;
  padding: 0;
  color: ${({ status, theme }) => status ? theme?.primary?.light : theme?.text?.secondaryLight};
  white-space: nowrap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  // display: ${({ isExpand }) => (isExpand ? "block" : "none")};
  padding-top: 5px;

  @media (max-width: 1025px) {
    padding-top: 0;
  }
`;

