import styled from "styled-components";

export const Wrapper = styled.div<{ $color?: string }>`
  max-width: 270px;
  height: fit-content;
  max-height: 500px;
  border-radius: 4px;
  /* border-top: 3px solid #79D4B6; */
  padding: 14px;
  background-color: #FFFFFF;
  gap: 4px;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25) !important;

  ${({ $color }) => $color && `
    border-top-color: ${$color};
  `}
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline
  height: 20px
`;

export const Title = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 11px;
  text-transform: capitalize; /* Assuming vertical trim refers to uppercase text */
  letter-spacing: 0.05em; /* Adjust letter-spacing as needed */
  line-height: 20px;
  color: #6D7784;

  span{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    color: #6D7784;
    margin-left: 4px;
  }
`;

export const SubTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 12px; 
  line-height: 20px; 
  color: #404040;
  margin-top: 6px;
  word-break: break-word;
`;

export const CloseBtn = styled.button`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  padding: 0;
  border: none;
  background-color: transparent;
  margin-top: -5px;
  font-weight: 500;

  svg g path{
    fill: #737373;
  }
`;

export const Goal = styled.button<{ $visible?: boolean }>`
  padding: 0;
  border: none;
  background-color: transparent;
  margin-left: 7px;
  visibility: hidden;
  display: flex;
  justify-content: space-between;
  width: 74px;
  align-items: center;
  margin-top: 4px;

  ${({ $visible }) => $visible && `
    visibility: visible !important;
  `}
`;

export const GoalTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: #6D7784;
  margin-right:10px;
  white-space: nowrap;
`;
