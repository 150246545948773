import { Tooltip } from "elements";
import { useNavigate } from "react-router-dom";
import useDeviceType from "../../../Hooks/ResponsiveHook";

import { Wrapper, Bar, Line, CircleLink, CircleDiv } from "./styled";

const Steps = ({ items, step, withLabels, withLinks }) => {
  function filterSteps(items) {
    const output = [];

    items.forEach((item) => {
      if (
        item.label === "1" ||
        item.label === "2" ||
        item.label === (step == 1 || step == 2 ? "3" : step.toString()) ||
        item.label === "Master Plan"
      ) {
        output.push(item);
      }
    });

    return output;
  }
  const navigate = useNavigate();
  const screenSizes = useDeviceType();
  const isMobile = screenSizes === "mobile";
  const isTablet = screenSizes === "iPad";

  const handleClick = (step) => (e) => {
    if (step === 1) {
      e.preventDefault();
      navigate(`?redirect=${e.target.pathname}`);
    }
  };

  const stepItems = isMobile ? filterSteps(items) : items;

  return (
    <Wrapper>
      {/* <Text style={{ display: notShow ? "none" : "block" }}>Step {step} of 10</Text> */}

      <Bar $withLabels={withLabels}>
        <Line />
        {isMobile ? (
          <>
            {" "}
            {stepItems?.map((item) =>
              withLinks ? (
                <Tooltip title={item.title} key={item.title}>
                  <CircleLink
                    to={item.path}
                    onClick={handleClick(step)}
                    $active={item.step === step}
                    $completed={item.step < step}
                    $color={item.color}
                    $label={item.label}
                    $labelWidth={item.labelWidth}
                    $withLabels={withLabels}
                  />
                </Tooltip>
              ) : (
                <CircleDiv
                  $active={item.step === step}
                  $completed={item.step < step}
                  $color={item.color}
                  $label={item.label}
                  $labelWidth={item.labelWidth}
                  $withLabels={withLabels}
                  key={item.title}
                />
              )
            )}
          </>
        ) : (
          <>
            {items?.map((item) =>
              withLinks ? (
                <Tooltip title={item.title} key={item.title}>
                  <CircleLink
                    to={item.path}
                    onClick={handleClick(step)}
                    $active={item.step === step}
                    $completed={item.step < step}
                    $color={item.color}
                    $label={item.label}
                    $labelWidth={item.labelWidth}
                    $withLabels={withLabels}
                  />
                </Tooltip>
              ) : (
                <CircleDiv
                  $active={item.step === step}
                  $completed={item.step < step}
                  $color={item.color}
                  $label={item.label}
                  $labelWidth={item.labelWidth}
                  $withLabels={withLabels}
                  key={item.title}
                />
              )
            )}
          </>
        )}
      </Bar>
    </Wrapper>
  );
};

export default Steps;
