import { css } from 'styled-components';

export const BREAKPOINTS = {
  xs: 449,
  sm: 768,
  md: 992,
  lg: 1256,
  xl: 1400,
  xl2: 1540,
  xxl: 1680
};

export const min =
  (breakpoint) =>
  (...args) =>
    css`
      @media (min-width: ${getSizeFromBreakpoint(breakpoint, 'min', BREAKPOINTS)}) {
        ${css(...args)};
      }
    `;

export const max =
  (breakpoint) =>
  (...args) =>
    css`
      @media (max-width: ${getSizeFromBreakpoint(breakpoint, 'max', BREAKPOINTS)}) {
        ${css(...args)};
      }
    `;

export const between =
  (firstBreakpoint, secondBreakpoint) =>
  (...args) =>
    css`
      @media (min-width: ${getSizeFromBreakpoint(
          firstBreakpoint,
          BREAKPOINTS,
        )}) and (max-width: ${getSizeFromBreakpoint(
          secondBreakpoint,
          BREAKPOINTS,
        )}) {
        ${css(...args)};
      }
    `;

const emSize = (pixelValue) => `${pixelValue}px`;

export const getSizeFromBreakpoint = (breakpointValue, breakpoint = 'max') => {
  if (BREAKPOINTS[breakpointValue]) {
    return emSize(BREAKPOINTS[breakpointValue] + (breakpoint === 'min' ? 1 : 0));
  } else if (parseInt(breakpointValue, 10)) {
    return emSize(BREAKPOINTS[breakpointValue] + (breakpoint === 'min' ? 1 : 0));
  }
  console.error(
    'styled-media-query: No valid breakpoint or size specified for media.',
  );
  return '0';
};
